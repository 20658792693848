import React from "react"
import Layout from "../../Components/layout"
import SEO from "../../Components/SEO"

export default function About() {
  return (
    <Layout>
      <SEO title="Shipping Policy" description="Shipping Policy"></SEO>
      <div className="policy">
        <h1>SHIPPING POLICY</h1>
        <p>
          <strong>For items available:</strong>
          <br />
          Fastlink allocates a 3-5 day shipping lead time for orders within
          Metro Manila. The use of Fastlink’s own personal courier and
          Third-party couriers (Pick-up)
        </p>
        <p>
          Fastlink allocates a 7-14 day shipping lead time for orders outside
          Metro Manila. The use of Third-party couriers (Same-day Delivery or
          Freight Courier e.g. LBC, DHL, etc.)
        </p>
        <p>
          <strong>For items that would be pre-ordered:</strong>
          <br />
          Fastlink allocates 30-45 days lead time depending on when the item
          will be available. Once the item is available, Fastlink will contact
          the email/phone number provided on the submitted pre-order form,
          following the same delivery duration applying above.
        </p>
        <p>
          You can always contact us for any questions at: inquiry@fastlink.ph
        </p>
      </div>
    </Layout>
  )
}
